import {Component, Input, OnInit, OnDestroy} from '@angular/core'

@Component({
  selector: 'collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],
})
export class CollapsableComponent implements OnInit, OnDestroy {
  @Input('title')
  title: string

  isCollapsed: boolean

  private arrowUp: string = 'ion-arrow-up-b'
  private arrowDown: string = 'ion-arrow-down-b'
  arrowClass: string

  constructor() {}

  ngOnInit() {
    this.isCollapsed = false
    this.arrowClass = this.arrowUp
  }

  ngOnDestroy() {}

  toggle() {
    this.isCollapsed = !this.isCollapsed
    if (this.isCollapsed) {
      this.arrowClass = this.arrowDown
    } else {
      this.arrowClass = this.arrowUp
    }
  }
}

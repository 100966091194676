import {Component, Input, OnInit, OnDestroy, ViewChild, TemplateRef, Output, EventEmitter} from '@angular/core'
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2'

@Component({
  selector: 'delete-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class DeleteConfirmationComponent implements OnInit, OnDestroy {
  @Input('itemToDelete')
  itemToDelete: string

  @Input('type')
  type: string = 'inline' // inline or button

  @Input('buttonText')
  buttonText: string

  @ViewChild('confirmModal')
  private confirmModal: any

  @Output('confirmed')
  confirmed = new EventEmitter<boolean>()

  swalType: string = 'warning'

  constructor(public readonly swalTargets: SwalPortalTargets) {}

  ngOnInit() {}

  ngOnDestroy() {}

  handleConfirm() {
    this.confirmed.emit(true)
  }

  show() {
    this.confirmModal.show()
  }
}

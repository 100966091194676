// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBrNvsdumd7N58p3s-bJf4xHW0cRzSZ5kw',
    authDomain: 'justpoliciestest.firebaseapp.com',
    databaseURL: 'https://justpoliciestest.firebaseio.com',
    projectId: 'justpoliciestest',
    storageBucket: 'justpoliciestest.appspot.com',
    messagingSenderId: '235657737876',
    appId: '1:235657737876:web:64af5b238b98cee5ea2681',
    measurementId: 'G-KJFX68GS1C',
  },
  rollbarConfig: {
    accessToken: 'ab8bf1733ec6421b9c2a80bc1354eb79',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: 'local',
      client: {
        javascript: {
          source_map_enabled: true, // true by default

          // -- Add this into your configuration ---
          code_version: 'b133d5678fb84cf45076de93401a0446feb4b171',
          // ---------------------------------------

          // Optionally have Rollbar guess which frames the error was
          // thrown from when the browser does not provide line
          // and column numbers.
          guess_uncaught_frames: true,
        },
      },
    },
  },
}

<swal
  #confirmationSwal
  [showCancelButton]="true"
  [confirmButtonColor]="'#f95372'"
  [cancelButtonColor]="'#009aff'"
  [focusCancel]="'true'"
  [buttonsStyling]="true"
  [footer]="'Click Cancel to abort or Continue to continue.'"
  (confirm)="handleConfirm()"
>
  <ng-container *swalPortal="swalTargets.title"> </ng-container>

<ng-container *swalPortal="swalTargets.content">
    <div class="modal-entry">
      <ng-content></ng-content>
    </div>
  </ng-container>

  <ng-container *swalPortal="swalTargets.confirmButton"> Continue </ng-container>

  <ng-container *swalPortal="swalTargets.cancelButton"> Cancel </ng-container>
</swal>

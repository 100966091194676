<i *ngIf="type == 'inline'" [swal]="confirmationSwal" class="delete-btn ion-trash-b cursor-pointer"></i>
<div *ngIf="type == 'button'" class="delete-entry cursor-pointer">
  <u [swal]="confirmationSwal">{{ buttonText }}</u>
</div>

<swal
  #confirmationSwal
  [showCancelButton]="true"
  [confirmButtonColor]="'#f95372'"
  [cancelButtonColor]="'#009aff'"
  [focusCancel]="'true'"
  [buttonsStyling]="true"
  [footer]="'Click Cancel to abort or Delete to continue.'"
  (confirm)="handleConfirm()"
>
  <ng-container *swalPortal="swalTargets.title"> </ng-container>

  <ng-container *swalPortal="swalTargets.content">
    <div class="delete-item">
      <p class="warning-txt text-center">WARNING!</p>
      <p class="warning-txt">This operation is permanent and cannot be undone! Please confirm deletion of:</p>
      <table class="delete-item-table">
        <tr>
          <td class="conflict-txt text-center">{{ itemToDelete }}</td>
        </tr>
      </table>
    </div>
  </ng-container>

  <ng-container *swalPortal="swalTargets.confirmButton"> Delete </ng-container>

  <ng-container *swalPortal="swalTargets.cancelButton"> Cancel </ng-container>
</swal>

<swal
  #alertSwal
  [showCancelButton]="false"
  [buttonsStyling]="true"
  [showCloseButton]="true"
  [confirmButtonColor]="'#009aff'"
  [footer]="'Tap to close.'"
  (confirm)="handleConfirm()"
>
  <ng-container *swalPortal="swalTargets.title"> </ng-container>

  <ng-container *swalPortal="swalTargets.content">
    <div class="modal-entry">
      <ng-content></ng-content>
    </div>
  </ng-container>
</swal>

import {Component, Input, OnInit, OnDestroy, ViewChild, TemplateRef, Output, EventEmitter} from '@angular/core'
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2'

@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationSwal')
  private confirmModal: any

  @Output('confirmed')
  confirmed = new EventEmitter<boolean>()

  swalType: string = 'warning'

  constructor(public readonly swalTargets: SwalPortalTargets) {}

  ngOnInit() {}

  ngOnDestroy() {}

  handleConfirm() {
    this.confirmed.emit(true)
  }

  show() {
    this.confirmModal.show()
  }
}

<div (click)="toggle()" style="cursor: pointer" [attr.aria-expanded]="!isCollapsed" aria-controls="textcontent">
  <div class="table collapsetoggle">
    <table>
      <tr>
        <td class="collapsetoggle"><i [ngClass]="arrowClass"></i>&nbsp;{{ title }}</td>
      </tr>
    </table>
  </div>
</div>
<div class="collapsable" id="textcontent" [ngbCollapse]="isCollapsed">
  <ng-content></ng-content>
</div>

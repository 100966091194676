import {Component, OnInit, OnDestroy, Input, Output, ViewChild, TemplateRef, EventEmitter} from '@angular/core'
import {Router} from '@angular/router'
import {Observable} from 'rxjs/Rx'

import {LocalDataSource} from 'ng2-smart-table'
import {SubscriptionManager} from 'app/c2-core/services/subscription.manager'

@Component({
  selector: 'item-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ItemListComponent implements OnInit, OnDestroy {
  configuration: any
  gridData: LocalDataSource

  @Input('columns')
  columns: any

  @Input('listData')
  listData: Observable<any[]>

  @Output('userRowSelect')
  userRowSelect = new EventEmitter<any>()

  subs: SubscriptionManager = new SubscriptionManager()

  constructor(private router: Router) {}

  ngOnInit() {
    this.configureTable()
    this.gridData = new LocalDataSource()
    this.gridData.load([])
    this.subs.next = this.listData.subscribe(data => {
      this.gridData.load(data)
    })
  }

  ngOnDestroy() {
    this.subs.stop()
  }

  view(event: any) {
    this.userRowSelect.emit(event.data)
  }

  configureTable() {
    this.configuration = {
      hideSubHeader: true, //hides the search/filter row
      add: {
        confirmCreate: false,
      },
      edit: {
        confirmSave: false,
      },
      delete: {
        deleteButtonContent: '<i class="ion-trash-a"></i>',
        confirmDelete: true,
      },
      pager: {
        display: true,
        perPage: 50,
      },
      columns: this.columns,
      actions: {
        position: 'right',
        add: false,
        edit: false,
        delete: false,
      },
    }
  }
}

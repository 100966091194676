import {Component, Input, OnInit, OnDestroy, ViewChild, TemplateRef, Output, EventEmitter} from '@angular/core'
import {SwalPortalTargets, SwalComponent} from '@sweetalert2/ngx-sweetalert2'

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  /* Sweet Alert types
    warning, error, success, info, and question
    */
  @Input('type')
  swalType: string = 'info'

  @Output('confirmed')
  confirmed = new EventEmitter<boolean>()

  @ViewChild('alertSwal')
  alertSwal: SwalComponent

  constructor(public readonly swalTargets: SwalPortalTargets) {}

  ngOnInit() {}

  ngOnDestroy() {}

  show() {
    this.alertSwal.fire()
  }

  handleConfirm() {
    this.confirmed.emit(true)
  }
}

import {Component, HostListener, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core'
import {DragulaService} from 'ng2-dragula'
import {UUID} from 'angular2-uuid'

/**
 * Convenience class for repeatable patterns using dragula.
 * 
 * Usage examples
 *   // USE TO WRAP A BLOCK OF CODE (RECOMENDED USE)
 *   <dragula-wrapper #dragulaWrapper (onDrop)="onDrop">
            <tbody [dragula]="dragulaWrapper.groupId" id="answerlist" [(dragulaModel)]="question.answers">
                <tr class="handle choice-spacing" *ngFor="let answerChoice of choices; let i = index" [attr.data-index]="i">
                    <td>
                        <table class="tab-updown">
                            <tr>
                                <td class="standardnumber">{{alphabet[i]}}</td>
                                <td class="move"><i class="fa fa-bars" aria-hidden="true"></i></td>
                            </tr>    
                        </table>
                    </td>
                    <td class="nopadding nomargin">
                        <div class="nopadding nomargin">
                            <textarea autosize id="answerID{{i}}" class="std-question" required rows="1" cols="80"
                                [(ngModel)]="question.answers[i]" name="answerName{{i}}"
                                placeholder="Add Choice" title="Add Choice.">
                            </textarea>
                        </div>
                    </td>
                    <td *ngIf="question.correctAnswerIdx !== i"><button class="btn btn-clear-wrong" title="Select the correct response."
                        (click)="makeCorrectAnswer(i)"><i class="fa fa-check" aria-hidden="true"></i></button></td>
                    <td *ngIf="question.correctAnswerIdx === i"><button class="btn btn-clear-right" title="Select the correct response."
                        [disabled]="true"><i class="fa fa-check" aria-hidden="true"></i></button></td>
                    <td class="text-center">
                        <button class="cursor-pointer std-del-btn btn-clear btn-trash" (click)="deleteChoice(i)" type="button" 
                            title="Delete"><i class="ion-trash-b"></i></button>
                    </td>
                </tr>
            </tbody> 
        </dragula-wrapper>         

        // USE IN PLACE OF <DVIV>
        <dragula-wrapper #dragulaWrapper (onDrop)="onDrop" class="row text-center text-lg-left" 
            [dragula]="dragulaWrapper.groupId" [dragulaModel]="training.slides">
            <div [ngClass]="{'col-lg-2': true, 'col-md-4': true, 'col-xs-6':true, 'slide-box': true, 'zoom': true, 'handle': true}" 
                *ngFor="let slide of training.slides; let i = index" [attr.data-index]="i" (click)="edit(i,slide)">
                <div class="img-responsive-16by9">
                    <img class="img-fluid thumb_img " src="{{slide.imageURL}}" alt="">
                </div>
                <div [ngClass]="{'slide-label':true, 'text-center':true, 'handle': screenWidth >= 1025}">Slide {{i+1}}</div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6 add-slide-box donotdrag" (click)="edit(-1,null)">
                <div dropZone [attr.disabled]="this.loading"
                    (hovered)="toggleHover($event)"
                    (dropped)="autoCreateSlide($event)"
                    [class.hovering]="isHovering">
                    <div class="img-responsive-16by9">
                        <img class="img-fluid thumb_img " src="assets/img/CC Add Slide plain.png" height="30px" alt=""/>
                    </div>
                    <div class='' class="add-slide-label text-center">Click here <br><i class="add-slide-icon fa fa-plus-circle" aria-hidden="true"></i><br>or Drop Image<br> to Add Slide </div>
                    <input type="file" style="display: none;" (change)="showThumb($event.target.files)">
                </div>
                <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
            </div>
        </dragula-wrapper>
 */

@Component({
  selector: 'dragula-wrapper',
  template: `<ng-content></ng-content>`,
})
export class DragulaWrapper implements OnInit, OnDestroy {
  @Input('verbose')
  verbose: boolean = false
  public groupId: string
  dragulaSub: any
  screenHeight: any // used for disabling on small screens
  screenWidth: any // used for disabling on small screens

  @Output('onDrop')
  onDrop = new EventEmitter<any>()

  constructor(private dragulaService: DragulaService) {
    this.groupId = UUID.UUID().toString()
    this.getScreenSize()
    this.dragulaService = dragulaService

    this.dragulaService.createGroup(this.groupId, {
      moves: (el, container, handle) => {
        if (this.screenWidth < 1025 || el.classList.contains('donotdrag')) {
          return false
        }
        if (el.classList.contains('handle')) {
          return true
        }
      },
    })
  }

  ngOnInit() {
    this.dragulaSub = this.dragulaService.dropModel(this.groupId).subscribe(({targetModel, item, sourceIndex, targetIndex}) => {
      if (this.verbose) {
        let param = {targetModel: targetModel, item: item, sourceIndex: sourceIndex, targetIndex: targetIndex}
        this.onDrop.emit(param)
      } else {
        this.onDrop.emit(targetModel)
      }
    })
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.groupId)
    if (this.dragulaSub) {
      this.dragulaSub.unsubscribe()
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight
    this.screenWidth = window.innerWidth
  }
}
